<template>
    <div id="no-page-404" class="screen">
        <h1 id="console" class="title has-text-centered">
            <span>'{{ currentPage }}' {{ face }}</span>
            <span>*** page not fount ***</span>
            <span>\\\ ?error 404 ///</span>
            <!-- <span>ASDFASDF</span> -->
            <!-- <span>ready.</span> -->
            <span>
                <router-link to="/" class="button is-large is-white is-outlined">
                    reboot</router-link>
            </span>
        </h1>
    </div>
</template>

<script>

export default {
    name: "About",
    data() {
        return {
            currentPage: null,
            face: null,
            faces: [
                "(◕︵◕)",
                "(╯︵╰,)",
                "(◡﹏◡✿)",
                "(︶︹︺)",
                "(✖﹏✖)",
                "(✖╭╮✖)",
                "(+_+)",
                "٩(×̯×)۶",
                "(￣□￣)",
            ],
        };
    },
    mounted() {
        this.currentPage = this.$route.path;
        this.face = this.faces[Math.floor(Math.random() * this.faces.length)];
        this.currentPage = this.currentPage.substring(1);
        console.log("currentPage:", this.currentPage);
    },
    computed: {
    },
};
</script>

<style lang="scss" scoped>
#no-page-404 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    height: 100dvh;
    display: grid;
    place-items: center;
    background: url(~@/assets/img/glitch.png) center repeat;
    background-size: 200%;
    animation: glitch 50s steps(3) infinite alternate;
    // border: 4rem solid blue;

    @keyframes glitch {
        0% {
            background-position: 0 50%;
        }

        2% {
            background-position: 140 90%;
        }

        24% {
            background-position: 23% 6%;
        }

        25% {
            background-position: 33% 67%;
        }

        40% {
            background-position: 95% -10%;
        }

        41% {
            background-position: 95% 0%;
        }

        50% {
            background-position: 90% 33%;
        }

        51% {
            background-position: 70% 38%;
        }

        75% {
            background-position: 75% 6%;
        }

        90% {
            background-position: -40% 0%;
        }

        100% {
            background-position: 0% 50%;
        }
    }

    #console {
        // position: absolute;
        // top: 0;
        // left: 0;
        // bottom: 0;
        // right: 0;
        // margin: 4rem;
        padding: 4rem;
        // flex-shrink: auto;
        // border-radius: 0.33rem;
        // background-color: rgba(97, 128, 230, 0.2);
        background-color: rgba(0, 0, 0, 0.66);
        backdrop-filter: blur(4px);
        // border: 4px solid white;
        overflow-y: auto;

        // scrollbar-width: thin;
        scrollbar-color: white transparent;

        &::-webkit-scrollbar {
            width: 0.5em;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: white;
            border-radius: 0px;
            // border: 0px solid orange;
        }
    }

    span {
        display: block;
        padding: 1rem 0;
    }

    & *::selection {
        color: black;
        background: white;
    }
}
</style>
